/*import React from 'react';
// Ensure Routes and Route are imported
import HomeScreen from './components/HomeScreen';
import GameScreen from './components/GameScreen';
import StepScreen from './components/StepScreen';

const App = () => (
  
);

export default App;*/


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import { Box, Typography, Grid, IconButton } from '@mui/material';

import WelcomeScreen from './components/WelcomeScreen';

const App = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(to bottom, #1C0909, #610162)',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '50px',
        paddingRight: '50px',
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<WelcomeScreen />} />

        </Routes>
      </Router>
    </Box>
  );
};

/*
          <Route path="/game/:gameId" element={<GameScreen />} />
          <Route path="/step/:stepId" element={<StepScreen />} />

*/
export default App;
