import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const WelcomeScreen = () => {
    const [welcomeStep, setWelcomeStep] = useState(0);

    const clickPrevButton = () => {
        if (welcomeStep != 0) setWelcomeStep(welcomeStep-1);
    };

    const clickNextButton = () => {
        setWelcomeStep(welcomeStep+1);
    };

    return  (
        <Box sx={{ maxWidth: '650px', minWidth: '375px', alignContent: 'center' }}>
            <center><img src="/images/wol-temp-logo.png" alt="Logo" style={{ width: '60%' }} /></center>
            
            <Typography
            variant="h6"
            sx={{
                color: '#ffffff',
                textAlign: 'center',
                marginTop: '70px',
                marginBottom: '20px',
                fontFamily: 'sans-serif',
            }}
            >
            Üdvözlünk a Wonders of Lights: Nightmare before Christmas-ban!
            </Typography>

            <Typography
            variant="body1"
            sx={{
                color: '#ffffff',
                textAlign: 'center',
                marginBottom: '30px',
                fontFamily: 'sans-serif',
            }}
            >
            Játssz velünk a látogatásod során és nyerj ajándékokat!
            </Typography>

            <Grid container justifyContent="center" sx={{ width: '100%', marginTop: '80px', marginBottom: '20px' }}>
            <Box sx={{ textAlign: 'center' }}>
                {welcomeStep > 0 ? (
                    <IconButton
                    sx={{
                        backgroundColor: '#ffffff',
                        padding: '10px',
                        marginRight: '20px',
                        borderRadius: '50%',
                        boxShadow: 3,
                    }}
                    onClick={clickPrevButton}
                    >
                    <ArrowBackIcon sx={{ color: '#000000' }} />
                    </IconButton>
                ) : (<span></span>)}
                <IconButton
                    sx={{
                        backgroundColor: '#ffffff',
                        padding: '10px',
                        borderRadius: '50%',
                        boxShadow: 3,
                    }}
                    onClick={clickNextButton}
                >
                    <ArrowForwardIcon sx={{ color: '#000000' }} />
                </IconButton>
            </Box>
            </Grid>
        </Box>
    );
};

export default WelcomeScreen;
